<template>
  <vue-markdown>
    {{ mdText }}
  </vue-markdown>
</template>

<script>
import VueMarkdown from '@adapttive/vue-markdown';
// eslint-disable-next-line
import mdText from '!raw-loader!../../assets/md-docs/privacy-policy.md';

export default {
  components: {
    VueMarkdown,
  },
  props: {
    mdFile: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mdText,
    };
  },
};
</script>
